<template>
  <app-action-btn
    :access="canWorkWithRemoveCollection"
    :show-tooltip="!canWorkWithRemoveCollection"
    bottom
    @click="canWorkWithRemoveCollection && askAndPerform(removeFromLibrary, getRemoveText)({
      group,
      libraryId: $route.params.id,
      collectionId: item.id,
    })">
    <template #iconWrapper>
      <v-icon
        :style="$route.name !== 'collections' ? 'font-size: 30px;' :'font-size: 16px;'">
        mdi-close-box-multiple
      </v-icon>
    </template>
    <span v-if="$route.name === 'collections'">Remove From Library</span>
  </app-action-btn>
</template>
<script>
import { askAndPerform } from '@/utils';
import {
  mapActions, mapGetters,
} from 'vuex';
export default {
  name: 'CollectionDelete',
  props: {
    item: {
      type: Object,
      default: null,
    },
    group: {
      type: String,
      default: () => '',
    },
    libraryType: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    getRemoveText() {
      return `Do you want to remove ${this.item.name} from this library?`;
    },
    ...mapGetters('UserRoles', ['canRemoveCollectionFromLibrary']),
    canWorkWithRemoveCollection() {
      return this.canRemoveCollectionFromLibrary(this.item.type);
    },
  },
  methods: {
    ...mapActions({
      removeFromLibrary: 'Collections/removeFromLibrary',
    }),
    askAndPerform,
  },
};
</script>
